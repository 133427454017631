import React from "react";
import RootPage from "../RootPage";

function DashboardPage() {
  return (
    <RootPage>
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        <h1>
          Cuando se habla de <br />
          movilidad sostenible
          <br />
          hablamos de Appimotion
        </h1>
        <h6>
          Aquí puedes administrar todas las funciones proporcionadas por la
          solución.
        </h6>
      </div>
    </RootPage>
  );
}

export default DashboardPage;
