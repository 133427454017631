/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  withStyles,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { AppBarStyles } from "./CommonStyles";
import { getTokenPayload } from "../../helpers/AppHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import { TOKEN_KEY } from "../../constants/general";
import * as routes from "../../constants/routes";

const AppBarComponent = ({ classes, history }) => {
  const { name } = getTokenPayload();
  const [fullName] = useState(name);
  const [anchor, setAnchor] = useState(null);

  const menuClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const logout = () => {
    LocalStorageHelper.remove(TOKEN_KEY);
    history.push(routes.PATH_LOGIN);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        classes={{
          root: classes.paper,
        }}
      >
        <Toolbar>
          <Typography className={classes.title}>
            {fullName}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={menuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={closeMenu}
              keepMounted
            >
              <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
            </Menu>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

AppBarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(withStyles(AppBarStyles)(AppBarComponent));
