import React, { useState } from "react";
import {
  Drawer,
  CssBaseline,
  Divider,
  withStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Typography,
  IconButton,
  Menu,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { DrawerStyles } from "./CommonStyles";
import { getTokenPayload } from "../../helpers/AppHelper";
import MenuCollapsedComponent from "./MenuCollapsedComponent";

import { TOKEN_KEY } from "../../constants/general";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import * as routes from "../../constants/routes";

const AppSiderBarComponent = ({ classes, menu, match, history }) => {
  const { name } = getTokenPayload();
  const [fullName] = useState(name);
  const [anchor, setAnchor] = useState(null);

  const menuClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const logout = () => {
    LocalStorageHelper.remove(TOKEN_KEY);
    history.push(routes.PATH_LOGIN);
  };

  const renderStandarMenu = (value) => (
    <ListItem
      button
      component={Link}
      to={value.url}
      selected={match.path.includes(value.url)}
    >
      <ListItemIcon>
        <i className="material-icons">{value.icon}</i>
      </ListItemIcon>
      <ListItemText primary={value.label} />
    </ListItem>
  );

  const renderCollapsedMenu = (value) => (
    <MenuCollapsedComponent value={value} />
  );

  return (
    <Drawer
      variant="permanent"
      className={classes.root}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <CssBaseline />
      <div className={classes.drawerHeader}>
        <Link to="/admin/dashboard">
          <img
            src="/images/appimotion-logo.png"
            alt="logo"
            className={classes.logo}
          />
        </Link>
      </div>
      <Divider />
      <List>
        {menu.map((value) => (
          <div key={value.menuId}>
            {value.subMenus && value.subMenus.length > 0
              ? renderCollapsedMenu(value)
              : renderStandarMenu(value)}
          </div>
        ))}
      </List>
      <div className={classes.logoutContainer}>
        <div>
          <Divider />
        </div>
        <AccountCircleIcon fontSize="large" style={{ color: "gray" }} />
        <Typography className={classes.title}>
          {fullName}
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={menuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={closeMenu}
            keepMounted
          >
            <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
          </Menu>
        </Typography>
      </div>
    </Drawer>
  );
};

AppSiderBarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  menu: PropTypes.array,
};

const mapStateToProps = (state) => ({
  isLoading: state.menu.isLoading,
  menu: state.menu.menu,
});

export default withRouter(
  connect(mapStateToProps)(withStyles(DrawerStyles)(AppSiderBarComponent))
);
