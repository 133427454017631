/* global grecaptcha */
import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const LoginFormComponent = ({
  handleSubmit,
  errors,
  touched,
  handleChange,
  handleBlur,
  isValid,
  isSubmitting,
  isLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRecaptcha = async () => {
    try {
      const token = await new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute("6Ldl0HUlAAAAACOJY9b4U6kmlokav3trBaK9R69L", {
              action: "login",
            })
            .then(resolve)
            .catch(reject);
        });
      });
      return token;
    } catch (error) {
      console.error("Error al obtener el token de reCAPTCHA:");
      throw error;
    }
  };
  const handleLogin = async () => {
    const recaptchaToken = await handleRecaptcha();
    if (recaptchaToken) {
      handleSubmit(recaptchaToken);
    }
  };

  return (
    <form className="login-form" action="">
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} className="form-grid">
          <TextField
            label="Correo electrónico"
            placeholder="Escribe tu correo"
            // variant="outlined"
            fullWidth
            shrink="true"
            error={errors.email && touched.email}
            helperText={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            name="email"
          />
        </Grid>

        <Grid item xs={12} className="form-grid">
          <TextField
            label="Contraseña"
            placeholder="Escribe tu contraseña"
            // variant="outlined"
            fullWidth
            shrink="true"
            error={errors.password && touched.password}
            type={showPassword ? "text" : "password"}
            helperText={errors.password}
            margin="normal"
            onChange={handleChange}
            onBlur={handleBlur}
            name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} className="form-grid">
          <Button
            className="submit"
            size="large"
            color="primary"
            variant="contained"
            onClick={handleLogin}
            disabled={isLoading || !isValid || isSubmitting}
          >
            Iniciar sesión
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div className="login-info">
            <div className="full-item text-center">
              <span>
                Gracias por usar Appimotion ©{new Date().getFullYear()}
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginFormComponent;
