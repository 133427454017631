/* eslint-disable */
import { createLogic } from "redux-logic";

import * as routeActions from "../actions/RouteActions";
import * as snackActions from "../actions/SnackActions";
import * as routeApi from "../api/v1/RouteApi";
import * as actionTypes from "../constants/actionTypes";
import * as routes from "../constants/routes";
import { getTokenPayload } from "../helpers/AppHelper";
import MessageCode from "../constants/messageCode";

const getBusinessRoutesByClientLogic = createLogic({
  type: actionTypes.ACTION_GET_BUSINESS_ROUTE_BY_CLIENT,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { clientId } = action.payload;
      const response = await routeApi.getBusinessRouteByClient(
        httpClient,
        clientId
      );
      dispatch(routeActions.getBusinessRoutesClientSuccess(response.data.list));
    } catch (err) {
      dispatch(routeActions.getBusinessRoutesClientFailure(err.messageText));
    }
    done();
  },
});

const addBusinessRoutesByClientLogic = createLogic({
  type: actionTypes.ACTION_ADD_BUSINESS_ROUTE_BY_CLIENT,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { businessInfo, history } = action.payload;
      const response = await routeApi.addBusinessRouteByClient(
        httpClient,
        businessInfo
      );

      if (response.data.valid) {
        dispatch(
          routeActions.addBusinessRoutesClientSuccess(response.data.messageCode)
        );
        history.push(routes.PATH_CLIENT_BUSINESS);
      } else {
        dispatch(snackActions.openSnack(true));
        dispatch(
          routeActions.getBusinessRoutesClientFailure(response.data.messageText)
        );
      }
    } catch (err) {
      dispatch(snackActions.openSnack(true));
      dispatch(routeActions.getBusinessRoutesClientFailure(err.messageText));
    }
    done();
  },
});

const getBusinessRoutesByByIdLogic = createLogic({
  type: actionTypes.ACTION_GET_BUSINESS_ROUTE_BY_CLIENT_BY_ID,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { businessId } = action.payload;
      const response = await routeApi.getBusinessRouteById(
        httpClient,
        businessId
      );

      if (response.data.valid) {
        dispatch(routeActions.getBusinessRouteByIdSuccess(response.data.item));
      } else {
        dispatch(snackActions.openSnack(true));
        dispatch(
          routeActions.getBusinessRouteByIdFailure(response.data.messageText)
        );
      }
    } catch (err) {
      dispatch(snackActions.openSnack(true));
      dispatch(routeActions.getBusinessRoutesClientFailure(err.messageText));
    }
    done();
  },
});

const deleteBusinessRoutesByByIdLogic = createLogic({
  type: actionTypes.ACTION_DELETE_BUSINESS_ROUTE_BY_CLIENT_BY_ID,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { businessId } = action.payload;
      const response = await routeApi.deleteBusinessRouteById(
        httpClient,
        businessId
      );

      if (response.data.valid) {
        dispatch(
          routeActions.deleteBusinessRouteByIdSuccess(response.data.messageText)
        );

        const { clientId } = getTokenPayload();
        const resGet = await routeApi.getBusinessRouteByClient(
          httpClient,
          clientId
        );

        if (resGet.data.valid) {
          dispatch(
            routeActions.getBusinessRoutesClientSuccess(resGet.data.list)
          );
        } else {
          dispatch(
            routeActions.getBusinessRoutesClientFailure(resGet.data.messageText)
          );
        }
      } else {
        dispatch(
          routeActions.deleteBusinessRouteByIdFailure(response.data.messageText)
        );
      }
    } catch (err) {
      dispatch(routeActions.getBusinessRoutesClientFailure(err.messageText));
    }
    dispatch(snackActions.openSnack(true));
    done();
  },
});

const removeBusinessReservesLogic = createLogic({
  type: actionTypes.ACTION_REMOVE_BUSINESS_RESERVES,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { businessId } = action.payload;
      const response = await routeApi.removeBusinessReserves(
        httpClient,
        businessId
      );

      if (response.data.valid) {
        dispatch(
          routeActions.removeBusinessReservesSuccess(
            response.data.messageText,
            response.data.messageCode
          )
        );
      } else {
        dispatch(
          routeActions.deleteBusinessRouteByIdFailure(
            response.data.messageText,
            MessageCode.general.warning
          )
        );
      }
    } catch (err) {
      dispatch(
        routeActions.removeBusinessReservesFailure(
          err.messageText,
          MessageCode.general.error
        )
      );
    }
    dispatch(snackActions.openSnack(true));
    done();
  },
});

const updateBusinessRoutesByByIdLogic = createLogic({
  type: actionTypes.ACTION_UPDATE_BUSINESS_ROUTE_BY_CLIENT_BY_ID,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { businessId, businessInfo, history } = action.payload;
      const response = await routeApi.updateBusinessRouteById(
        httpClient,
        businessId,
        businessInfo
      );

      if (response.data.valid) {
        dispatch(
          routeActions.updateBusinessRouteByIdSuccess(response.data.messageText)
        );
        history.push(routes.PATH_CLIENT_BUSINESS);
      } else {
        dispatch(snackActions.openSnack(true));
        dispatch(
          routeActions.updateBusinessRouteByIdFailure(response.data.messageText)
        );
      }
    } catch (err) {
      dispatch(snackActions.openSnack(true));
      dispatch(routeActions.updateBusinessRouteByIdFailure(err.messageText));
    }
    done();
  },
});

//New

const getBusinessRoutesAllClientLogic = createLogic({
  type: actionTypes.ACTION_GET_BUSINESS_ROUTE_ALL_CLIENT,
  latest: true,
  async process({ httpClient }, dispatch, done) {
    try {
      const response = await routeApi.getBusinessRouteAllClient(httpClient);
      dispatch(
        routeActions.getBusinessRouteAllClientSuccess(response.data.list)
      );
    } catch (err) {
      dispatch(routeActions.getBusinessRouteAllClientFailure(err.messageText));
    }
    done();
  },
});

//New2

const addBusinessRoutesToAClientLogic = createLogic({
  type: actionTypes.ACTION_ADD_BUSINESS_ROUTE_TO_A_CLIENT,
  latest: true,
  async process({ httpClient, action }, dispatch, done) {
    try {
      const { businessId } = action.payload;
      const response = await routeApi.addBusinessRouteToAClient(
        httpClient,
        businessId
      );

      if (response.data.valid) {
        dispatch(
          routeActions.addBusinessRouteToAClientSuccess(
            response.data.messageText,
            response.data.messageCode
          )
        );
        const { clientId } = getTokenPayload();
        const refreshData = await routeApi.getBusinessRouteByClient(
          httpClient,
          clientId
        );
        if (refreshData.data.valid) {
          dispatch(
            routeActions.getBusinessRoutesClientSuccess(refreshData.data.list)
          );
        }
      } else {
        dispatch(
          routeActions.addBusinessRouteToAClientFailure(
            response.data.messageText,
            response.data.messageCode
          )
        );
      }
    } catch (err) {
      dispatch(routeActions.addBusinessRouteToAClientFailure(err.message));
    }
    //dispatch(snackActions.openSnack(true));
    done();
  },
});

export default [
  getBusinessRoutesByClientLogic,
  addBusinessRoutesByClientLogic,
  getBusinessRoutesByByIdLogic,
  deleteBusinessRoutesByByIdLogic,
  updateBusinessRoutesByByIdLogic,
  removeBusinessReservesLogic,
  getBusinessRoutesAllClientLogic,
  addBusinessRoutesToAClientLogic,
];
