/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React from "react";
import SearchInputComponent from "../../common/SearchInputComponent";
import { getTokenPayload } from "../../../helpers/AppHelper";
import TableCommon from "../../common/TableCommon";

function FreeTime() {
  const filterByName = async (e) => {
    const { target } = e;
    const { getUsers } = this.props;
    const { clientId } = getTokenPayload();
    const { take } = this.state;
    const searchTerm = target.value;

    await getUsers(clientId, 0, take, searchTerm);
  };

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ marginRight: "10px" }}>Tiempo Libre</h2>
        <SearchInputComponent filterBy={filterByName} />
      </div> */}
      <TableCommon
        title="Tiempo Libre"
        columns={[
          { title: "Nombre", field: "name" },
          { title: "Fecha de descanso", field: "capacity" },
          { title: "Jornada", field: "startHour" },
          { title: "Aprobación", field: "destination.longitude" },
        ]}
      />
    </>
  );
}

export default FreeTime;
