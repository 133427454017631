import React from "react";
import NotificationsListComponent from "./NotificationsListComponent";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as NotificationsActions from "../../../../actions/notificationsActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import LinearProgress from "@material-ui/core/LinearProgress";

const animatedComponents = makeAnimated();

class NotificationsComponent extends React.Component {
  componentDidMount() {
    const { getAllDomains } = this.props;
    getAllDomains();
  }

  constructor() {
    super();
    this.state = {
      showList: true,
      contNum: 1,
      checked: 0,
      title: "",
      content: "",
      deliveryDateTime: "",
      deliveryAsap: false,
      domains: "",
      domindGroup: true,
      spinner: false,
    };
  }

  ShowList = () => {
    this.setState({
      showList: this.state.showList ? false : true,
    });
  };

  selectorChange = (domains) => {
    var valueDomains = [];

    domains.forEach((valor) => {
      if (valor) {
        let domainId = valor.value;
        valueDomains.push({ domainId });
      }
    });
    this.setState({
      domains: valueDomains,
    });
  };

  addNotifi = async (evt) => {
    const { addNotifications } = this.props;

    if (
      this.state.title === "" ||
      this.state.content === "" ||
      this.state.deliveryDateTime === "" ||
      this.state.domains === 0
    ) {
      toast.error("¡Debe llenar todos los campos!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "fw-bold",
      });
    } else {
      this.setState({
        spinner: true,
      });

      const newData = {
        title: this.state.title,
        content: this.state.content,
        deliveryAsap: this.state.deliveryAsap,
        deliveryDateTime: this.state.deliveryDateTime,
        domains: this.state.domains,
      };

      const newNotifications = {
        ...newData,
      };
      await addNotifications(newNotifications);
    }
  };

  handleTextField = (e) => {
    this.setState({ textValue: e.target.value });
  };

  diableDate = () => {
    this.setState({
      checked: this.state.checked === 0 ? -1 : 0,
    });

    let hoy = new Date();
    let fecha =
      hoy.getFullYear() +
      "-" +
      ("0" + (hoy.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + hoy.getDate()).slice(-2) +
      "T";
    let hora =
      (hoy.getHours() < 10 ? "0" : "") +
      hoy.getHours() +
      ":" +
      (hoy.getMinutes() < 10 ? "0" : "") +
      hoy.getMinutes();

    let fechaHora = fecha + "" + hora;

    if (this.state.checked === 0) {
      toast.warn("Se enviara la fecha y hora actuales.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "fw-bold",
      });
    }

    if (this.state.checked === -1) {
      this.setState({
        deliveryDateTime: "",
        deliveryAsap: false,
      });
    } else {
      this.setState({
        deliveryDateTime: fechaHora,
        deliveryAsap: true,
      });
    }
  };

  clearModal = () => {
    this.setState({
      title: "",
      content: "",
      deliveryDateTime: "",
      checked: 0,
      deliveryAsap: false,
    });
  };

  domainAlone = (domainAlone) => {
    let domainId = domainAlone.value;
    let domains = [];

    domains.push({ domainId });

    this.setState({ domains: domains });
  };

  render() {
    const { messageCode, domains } = this.props;
    let domainsSelect = [];

    if (messageCode === 200 && this.state.contNum === 1) {
      toast.info("Notificación agregada correctamente", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "fw-bold",
      });

      this.setState({
        spinner: false,
        contNum: 0,
      });
    }

    if (domains) {
      var domainsValues = [];

      domains.forEach((valor) => {
        if (valor) {
          let value = valor.domainId;
          let label = valor.name;
          domainsValues.push({ value, label });
        }
      });

      if (domainsValues.length === 1) {
        this.setState({ domindGroup: false });
        let domainA = [];
        domainA = domainsValues[0];
        this.domainAlone(domainA);
      } else {
        domainsSelect = domainsValues;
      }
    }

    return (
      <div>
        {this.state.showList && (
          <NotificationsListComponent></NotificationsListComponent>
        )}

        {this.state.showList && (
          <div className="mt-3 d-md-flex justify-content-md-end">
            <Button
              color="primary"
              className="mb-3"
              variant="contained"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Agregar
            </Button>
          </div>
        )}

        {/* <!-- Modal --> */}
        <div
          className="modal fade "
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="false"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <Typography variant="h5" component="h2">
                  Envio de notificaciones
                </Typography>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={this.clearModal}
                ></button>
              </div>

              <div className="modal-body">
                <Card variant="outlined">
                  <CardContent>
                    <form>
                      <div className="container">
                        <div className="mt-3">
                          {this.state.domindGroup && (
                            <Select
                              closeMenuOnSelect={false}
                              placeholder={"Seleccione la empresa"}
                              components={animatedComponents}
                              isMulti
                              options={domainsSelect}
                              onChange={this.selectorChange}
                            />
                          )}
                        </div>

                        <div className="mt-3">
                          <TextField
                            id="titleNotification"
                            label="Titulo de la notificacion"
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            variant="outlined"
                            style={{ zIndex: "0" }}
                            value={this.state.title}
                            onChange={(evt) =>
                              this.setState({
                                title: evt.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="mt-3">
                          <TextField
                            value={this.state.content}
                            onChange={(evt) =>
                              this.setState({
                                content: evt.target.value,
                              })
                            }
                            fullWidth
                            style={{ zIndex: "0" }}
                            id="messageNotification"
                            label="Mensaje"
                            multiline
                            variant="outlined"
                            minRows={8}
                            inputProps={{ maxLength: 500 }}
                          />
                        </div>

                        <div className="mt-3">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.checked === -1}
                                onChange={this.diableDate}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label="Enviar ahora."
                          />
                        </div>

                        <div className="mt-3">
                          <TextField
                            value={this.state.deliveryDateTime}
                            onChange={(evt) =>
                              this.setState({
                                deliveryDateTime: evt.target.value,
                              })
                            }
                            fullWidth
                            disabled={this.state.checked === -1}
                            id="datetime"
                            label="Fecha de notificacion"
                            type="datetime-local"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </CardContent>
                </Card>
              </div>

              <div className="modal-footer">
                <Button
                  color="primary"
                  className="mb-3"
                  variant="contained"
                  onClick={this.addNotifi}
                >
                  Enviar
                </Button>

                {this.state.spinner && (
                  <LinearProgress style={{ width: "100%" }} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotificationsComponent.propTypes = {
  addNotifications: PropTypes.func,
  getAllDomains: PropTypes.func,
  domains: PropTypes.array,
};

const mapStateToProps = (state) => ({
  messageCode: state.notification.messageCode,
  addNotifications: state.notification.addNotifications,
  domains: state.notification.domains,
  isLoading: state.notification.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  addNotifications: bindActionCreators(
    NotificationsActions.addNotifications,
    dispatch
  ),
  getAllDomains: bindActionCreators(
    NotificationsActions.getAllDomains,
    dispatch
  ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsComponent);
